// extracted by mini-css-extract-plugin
export var dark = "r_sD";
export var darkcookie = "r_sF";
export var tintedcookie = "r_sG";
export var regularcookie = "r_sH";
export var darkbase = "r_sJ";
export var tintedbase = "r_sK";
export var regularbase = "r_sL";
export var darkraw = "r_sM";
export var tintedraw = "r_sN";
export var regularraw = "r_sP";
export var darkchick = "r_sQ";
export var tintedchick = "r_sR";
export var regularchick = "r_sS";
export var darkherbarium = "r_sT";
export var tintedherbarium = "r_sV";
export var regularherbarium = "r_sW";
export var darkholiday = "r_sX";
export var tintedholiday = "r_sY";
export var regularholiday = "r_sZ";
export var darkoffline = "r_s0";
export var tintedoffline = "r_s1";
export var regularoffline = "r_s2";
export var darkorchid = "r_s3";
export var tintedorchid = "r_s4";
export var regularorchid = "r_s5";
export var darkpro = "r_s6";
export var tintedpro = "r_s7";
export var regularpro = "r_s8";
export var darkrose = "r_s9";
export var tintedrose = "r_tb";
export var regularrose = "r_tc";
export var darktimes = "r_td";
export var tintedtimes = "r_tf";
export var regulartimes = "r_tg";
export var darkwagon = "r_th";
export var tintedwagon = "r_tj";
export var regularwagon = "r_tk";