// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_ts";
export var lbContainerInner = "t_tt";
export var movingForwards = "t_tv";
export var movingForwardsOther = "t_tw";
export var movingBackwards = "t_tx";
export var movingBackwardsOther = "t_ty";
export var lbImage = "t_tz";
export var lbOtherImage = "t_tB";
export var prevButton = "t_tC";
export var nextButton = "t_tD";
export var closing = "t_tF";
export var appear = "t_tG";