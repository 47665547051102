// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "q_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "q_fR d_fR d_bz d_bJ";
export var menuDesign6 = "q_r5 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "q_r6 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "q_r7 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "q_r8 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "q_r9 d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "q_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "q_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "q_sb";
export var navbarItem = "q_nb d_bx";
export var navbarLogoItemWrapper = "q_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "q_sc d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "q_sd d_gd d_by d_Z d_bs";
export var burgerToggle = "q_sf d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "q_sg d_gd d_by d_Z d_bs";
export var burgerInput = "q_sh d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "q_sj d_f3 d_w d_H";
export var burgerLine = "q_sk d_f1";
export var burgerMenuDesign6 = "q_sl d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "q_sm d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "q_sn d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "q_sp d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "q_sq d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "q_sr d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "q_ss d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "q_st d_bC d_bP";
export var compact = "q_sv";
export var navDivided = "q_sw";
export var staticBurger = "q_sx";
export var menu = "q_sy";
export var navbarDividedLogo = "q_sz";
export var nav = "q_sB";
export var fixed = "q_sC";